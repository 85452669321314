// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.is-header {
	position: absolute;
	top:0;
	left:0;
	right:0;
	padding: 39px 15px 0;
	z-index: 10;
	margin: 0 auto;
	max-width: 1920px;
	@media (max-width: 1279px) {
		padding: 17px 15px 0;
	}

	&__container {
		display: flex;
		align-items: center;
	}

	&__logo {
		display: block;
		@media (max-width: 1279px) {
			max-width: 50px;
		}
	}

	&__left {
		@media (min-width: 1851px) {
			padding: 0 0 0 50px;
		}
	}

	&__right {
		display: flex;
		align-items: center;
		flex:1;
	}

	&__menu {
		display: flex;
		align-items: center;
		flex:1;
		@media (max-width: 1279px) {
			display: block;
			opacity: 0;
			visibility: hidden;
			transition:visibility 0.2s linear,opacity 0.2s linear;
			background: linear-gradient(90deg, rgba(255, 255, 255, 0.21) -29.55%, rgba(255, 255, 255, 0) 71.48%), #213460;
			position: fixed;
			z-index: 9;
			top:0;
			left:0;
			right:0;
			bottom:0;
			
			&.active {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&__menu-inner {
		display: flex;
		align-items: center;
		flex:1;
		position: relative;
		@media (max-width: 1279px) {
			display: block;
			height: 100vh;
			position: relative;
			overflow: hidden;
		}
	}

	&__menu-scrollable {
		display: flex;
		align-items: center;
		flex:1;
		@media (max-width: 1279px) {
			display: block;
			-webkit-overflow-scrolling: touch;
			overflow: auto;
			height: calc(100vh - 70px);
			padding: 42px 0 0;
		}
	}

	&__mobile-logo {
		max-width: 230px;
		margin: 0 0 0 40px;
		@media (min-width: 1280px) {
			display: none;
		}
	}

	&__social {
		color:#fff;
		font-size: 14px;
		position: absolute;
		left:0;
		right:0;
		bottom: 0;
		@media (min-width: 1280px) {
			display: none;
		}

		.social {
			border-top: 1px solid #D2D2D2;
			padding: 21px 37px 27px 37px;
			.is-link {
				display: inline-block;
				vertical-align: middle;
				margin-left: 32px;
			}
		}
	}

	&__btns {
		display: flex;
		align-items: center;
		margin-left: auto;
		.is-button + .is-button {
			margin-left: 30px;
		}
		@media (min-width: 1851px) {
			padding: 0 35px 0 0;
		}
		@media (max-width: 1279px) {
			margin:55px 0 38px;
			padding: 0 15px 0 48px;
			.is-button + .is-button {
				margin-left: 17px;
			}
		}
	}

	&__mobile-nav-opener {
		padding: 17px 12px;
		position: relative;
		z-index: 10;
  	display: inline-block;
  	margin: 0 0 0 auto;
	  font-size: 0;
	  user-select: none;
	  white-space: nowrap;
	  text-decoration: none;
	  border: 0;
	  outline: 0;
	  background: 0 0;
	  touch-action: manipulation;
	  overflow: visible;
	  font-family: inherit;
	  text-align: center;
	  cursor:pointer;
  	@include on-event {
	  	text-decoration: none;
	  }
		@media (min-width: 1280px) {
			display: none;
		}
	}
}