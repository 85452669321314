// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
.is-footer {
	background: #032456;

	&__logo {
		display: block;
		margin: 0 58px 0 0;
		max-width: 62px;
		@media (max-width: 1100px) {
			display: none;
		}
	}

	&__top {
		display: flex;
		align-items: center;
		padding: 15px 75px;
		@media (max-width: 1550px) {
			padding: 15px;
		}
		@media (max-width: 1100px) {
			display: block;
			padding: 42px 37px 55px;
		}
	}

	&__main {
		flex:1;
		display: flex;
		@media (max-width: 1100px) {
			display: block;
		}
	}

	&__seal {
		display: flex;
		align-items: flex-end;
		.site-seal + .site-seal {
			margin-left: 49px;
		}
		@media (max-width: 1100px) {
			.site-seal + .site-seal {
				margin-left: 0;
			}
			.site-seal {
				margin: 0 29px 0 0;
			}
		}
	}

	&__bottom {
		background: #010E23;
		color:#fff;
		min-height: 58px;
		display: flex;
		align-items: stretch;
		@media (max-width: 1100px) {
			flex-wrap: wrap;
		}
		@media (max-width: 768px) {
			display: block;
		}
		.copyright , .offices {
			border-right: 1px solid #575757;
		}
		.copyright {
			min-width: 422px;
			font-size: 14px;
			line-height: 22px;
			padding: 19px 5px 17px 5px;
			@media (max-width: 1550px) {
				min-width: 320px;
			}
			@media (max-width: 1100px) {
				width: 100%;
				border-bottom: 1px solid #575757;
				border-right: none;
			}
			@media (max-width: 768px) {
				border:none;
				padding: 19px 37px 17px 37px;
				text-align: left;
			}
		}
		.social {
			margin-left: auto;
			border-left: 1px solid #575757;
			padding: 19px 72px 17px 60px;
			.is-link {
				display: inline-block;
				vertical-align: middle;
				margin-left: 32px;
			}
			@media (max-width: 1550px) {
				padding: 19px 20px 17px 20px;
				.is-link {
					margin-left: 25px;
				}
			}
			@media (max-width: 768px) {
				margin: auto;
				border-top: 1px solid #575757;
				padding: 19px 37px 17px 37px;
			}
		}
		.offices {
			padding: 5px 40px 15px 52px;
			.office {
				display: inline-block;
				vertical-align: bottom;
				margin:14px 0 0 47px;
			}
			@media (max-width: 1550px) {
				padding: 5px 20px 15px 20px;
				.office {
					margin: 14px 0 0 25px;
				}
			}
			@media (max-width: 768px) {
				padding: 5px 12px 15px 37px;
				font-size: 16px;
				.office {
					margin: 14px 25px 0 0;
				}
				span:first-child {
					display: inline-block;
					margin: 14px 25px 0 0;
				}
			}
		}
	}

	&__nav {
		display: flex;
		align-items: center;
		@media (max-width: 1100px) {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			margin: 0 0 60px 0;
		}
	}

	&__nav-item {
		@include SansSemi;
		@include font(#fff, 20px, 1.5);
		@include on-event {
			color: #fff;
		}
		& + #{&} {
			margin-left: 99px;
		}
		@media (max-width: 1550px) {
			font-size: 18px;
			line-height: 1.66;
			& + #{&} {
				margin-left: 40px;
			}
		}
		@media (max-width: 1100px) {
			font-size: 20px;
			line-height: 1.66;
			& + #{&} {
				margin: 32px 0 0;
			}
		}
	}
}