// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.page-index {
	background: #F2F2F2;
	&__hero {
		
	}

	&__tabs {
		position: relative;
		z-index: 3;
		margin: -35px 0 77px;
		@media (max-width: 700px) {
			margin: -35px 0 15px;
		}
		.tabs-footer {
			position: relative;
			margin-top: -35px;
			min-height: 89px;
			background: linear-gradient(90deg, rgba(255, 255, 255, 0.21) -29.55%, rgba(255, 255, 255, 0) 71.48%), #213460;
			box-shadow: 1.7px 4.7px 21px rgba(0, 0, 0, 0.12);
			border-bottom-left-radius: 6px;
			border-bottom-right-radius: 6px;
			padding: 0 38px 17px;
			display: flex;
			align-items: center;

			.rounded-link {
		    margin: 0;
		    padding: 0;
		    font-size: 0;
		    user-select: none;
		    white-space: nowrap;
		    text-decoration: none;
		    border: 0;
		    outline: 0;
		    touch-action: manipulation;
		    overflow: visible;
		    font-family: inherit;
		    text-align: center;
		    cursor:pointer;
		    position: absolute;
		    z-index: 1;
		    right: 388px;
		    top: -43px;
		    width: 78px;
		    height: 78px;
		    background: #fff;
		    border-radius: 40px;
		    svg {
		      position: absolute;
		      display: block;
		      left: 50%;
		      transform: translateX(-50%);
		      bottom: 22px;
		    }
		    @media (max-width: 768px) {
		    	width: 44px;
		    	height: 44px;
		    	right: 15px;
		    	top:-22px;
		    	svg {
		    		width: 13px;
		    		bottom: 15px;
		    	}
		    }
		  }
			.left {
				flex:1;
				display: flex;
				align-items: start;
				padding-top: 19px;
				.responsive-img {
					margin: 14px 28px 0 0;
				}
			}
			.heading {
				display: block;
				@include MontserratSemi;
				@include font(#fff, 18px, 26px);
			}
			.note {
				display: block;
				@include SansSemi;
				@include font(#fff, 12px, 22px);
			}
			.right {
				@include SansSemi;
				padding-top: 24px;
				.is-button {
					@include font(#fff, 14px, 20px);
					text-align: left;
				}
			}
			@media (max-width: 700px) {
				display: block;
				border-radius: 0;
				padding: 14px 37px 27px;
				.left .responsive-img {
					display: none;
				}
				.heading {
					font-size: 16px;
					line-height: 1.25;
					margin: 0 0 11px;
				}
				.note {
					line-height: 1.5;
				}
				.right {
					padding: 0;
					margin: 32px 0 0;
					.is-button br {
						display: none;
					}
				}
			}
		}
		.is-table {
			padding: 0 0 35px;
		}
		@media (max-width: 1279px) {
			.wrapper {
				padding: 0;
			}
		}
	}

	&__wwd {
		background: linear-gradient(270.97deg, rgba(255, 255, 255, 0.21) -29.55%, rgba(255, 255, 255, 0) 71.48%), #213460;
		box-shadow: 1.7px 4.7px 21px rgba(0, 0, 0, 0.12);
		margin: 77px 0 0;
		padding: 86px 0;
		background-repeat: no-repeat;
		background-position: right center;
		background-size: 49%;
		min-height: 652px;
		@include is-grid(2,80);
		.is-grid__item {
			margin-bottom: 72px;
		}
		.header.is-grid__item {
			margin-bottom: 75px;
		}

		.heading {
			@include MontserratB;
			@include font(#fff,26px,32px);
			margin: 0;
		}
		@media (max-width: 1279px) {
			@include is-grid(2,40);
			.is-grid__item {
				margin-bottom: 34px;
			}
		}
		@media (max-width: 1100px) {
			padding: 86px 0 82%;
	    background-position: center bottom 35px;
	    background-size: contain;
			@include is-grid(1,42);
			.is-columns {
				display: block;
			}
		}
		@media (max-width: 768px) {
			padding: 38px 0 82%;
			.heading {
				font-size: 18px;
			}
			.is-grid.header .is-grid__item {
				margin-bottom: 32px;
			}
		}
		@media (max-width: 700px) {
			margin: 15px 0 0;
		}
	}

	&__eco-system {
		padding: 77px 0;
		.long-title {
			color: #757575;
			@include SansSemi;
		}
		.es-tiles {
			padding: 54px 0 39px;
			@media (max-width: 1000px) {
				padding: 0 25px;
				margin: 54px auto 0;
				max-width: 600px;
			}
			@media (max-width: 768px) {
				margin: 30px auto 0;
			}
		}
		@media (max-width: 768px) {
			padding: 54px 0 60px;
		}
	}

	&__industries {
		margin: 0 0 77px;
		@include is-grid(4,24);
		.section-heading {
			margin: 0 0 52px 0;
		}
		@media (max-width: 768px) {
			margin: 0 0 11px;
			@include is-grid(2,24);
		}
		@media (max-width: 600px) {
			@include is-grid(1,48);
			.section-heading {
				margin: 0 0 30px 0;
			}
		}
	}

	&__partners {
		padding-bottom: 65px;
		@media (max-width: 768px) {
			padding-bottom: 35px;
		}
	}
}