.wrapper {
  position: relative;
  width: 100%;
  max-width: 1286px;
  margin: 0 auto;
  padding: 0 15px;
  @media (max-width: 768px) {
  	padding: 0 37px;
  }
}

.wrapper-a {
	max-width: 1010px;
}