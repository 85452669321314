.is-columns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: space-between;
  .col {
    flex-grow: 1;
    width: 100%;
  }
  .col + .col {
    margin-left: 32px;
  }
  &.gap-0 {
    .col + .col { margin-left: 0; }
  }
  &.gap-10 {
    .col + .col { margin-left: 10px; }
  }
  &.gap-32 {
    .col + .col { margin-left: 32px; }
  }
  &.gap-40 {
    .col + .col { margin-left: 40px; }
  }
}