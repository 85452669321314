// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/Open_Sans/OpenSans-SemiBold.eot');
    src:
        url('../fonts/Open_Sans/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Open_Sans/OpenSans-SemiBold.woff2') format('woff2'),
        url('../fonts/Open_Sans/OpenSans-SemiBold.woff') format('woff'),
        url('../fonts/Open_Sans/OpenSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/Open_Sans/OpenSans-Regular.eot');
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
        url('../fonts/Open_Sans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Open_Sans/OpenSans-Regular.woff2') format('woff2'),
        url('../fonts/Open_Sans/OpenSans-Regular.woff') format('woff'),
        url('../fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
        url('../fonts/Montserrat/Montserrat-SemiBold.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
        url('../fonts/Montserrat/Montserrat-Bold.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Medium'), local('Montserrat-Medium'),
        url('../fonts/Montserrat/Montserrat-Medium.woff2') format('woff2'),
        url('../fonts/Montserrat/Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@mixin MontserratSemi {
  font-family: 'Montserrat';
  font-weight: 600;
}

@mixin MontserratB {
  font-family: 'Montserrat';
  font-weight: 700;
}

@mixin SansSemi {
  font-family: 'Open Sans';
  font-weight: 600;
}

@mixin SansR {
  font-family: 'Open Sans';
  font-weight: normal;
}

