.icon-item {
	
	&__img {
		margin: 0 0 18px;
		@media (max-width: 768px) {
			margin: 0 0 22px;
		}
	}

	&__text {
		@include SansSemi;
		@include font(#D2D2D2 ,16px,26px);
		margin: 0;
		@media (max-width: 768px) {
			font-size: 14px;
			line-height: 22px;
		}
	}
}