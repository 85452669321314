.is-tabs {

	&__nav {
		position: relative;
		padding: 0 16px;
		box-shadow: 20px 20px 40px rgba(35, 111, 153, 0.05);
		border-radius: 35px;
		overflow: hidden;
		background: #fff;
		z-index: 1;
		@media (max-width: 1279px) {
			margin: 0 -46px 0 15px;
			padding: 0 30px 0 16px;
		}
		@media (max-width: 768px) {
			margin: 0 -46px 0 24px;
			padding: 0 46px 0 16px;
		}
	}

	&__nav-container {
		box-sizing: border-box;
	  margin: 0;
	  display: flex;
	  flex-direction: row;
	  flex-wrap: nowrap;
	  align-items: center;
	  justify-content: flex-start;
	  @media (max-width: 1279px) {
	  	-webkit-overflow-scrolling: touch;
			overflow: auto;
	  }
	}

	&__link {
		position: relative;
	  display: block;
	  z-index: 20;
	  text-align: center;
	  padding: 24px 40px;
	  white-space: nowrap;

	  @media (max-width: 1279px) {
	  	flex:1 1 auto;
	  }

	  &::after {
	  	content: '';
	  	position: absolute;
	  	display: block;
	  	width: 1px;
	  	height: 32px;
	  	background: #D2D2D2;
	  	left:100%;
	  	top:19px;
	  }
	  
	  &:last-child::after {
	  	content: none;
	  }
	  
	  &.active {
	    color: $purple;
	  }
	}

	&__container {
		display: block;
	  position: relative;
	  overflow: hidden;
	  height: auto;
	  padding: 35px 0 35px 0;
  	margin-top: -35px;
  	background: #F9F9F9;
	}

	&__wrapper {
		display: block;
    position: relative;
    &:after {
      content: '';
      display: block;
      height: 1px;
    }
	}

	&__tab {
		display: none;
    &:first-child {
      display: block;
    }
    @media (max-width: 1279px) {
    	margin: 0 -15px;
    }
	}
}
