/**
 * Basic typography style for copy text
 */
html {
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
}
body {
  color: $text-color;
  font: normal 100% / 1.625 $text-font-stack;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  font-style: normal;
  font-weight: normal;
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-numeric: lining-nums;
	-moz-font-feature-settings: 'lnum';
	-webkit-font-feature-settings: 'lnum';
	font-feature-settings: 'lnum' on;
}

.section-heading {
  margin:0;
  @include MontserratB;
  font-size: 26px;
  line-height: 32px;
  color:#000;
  @media (max-width: 768px) {
    font-size: 22px;
  }
}

.paragraph {
  margin: 0;
  & + #{&} {
    margin-top: 15px;
  }
}

