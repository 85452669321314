.p-slider {
	.swiper-wrapper {
		align-items: stretch;
	}
	.swiper-slide {
		width: auto !important;
		height: auto;
		display: flex;
		align-items: center;
		padding: 0 40px;
	}

	.swiper-pagination-bullets {
		text-align: center;
		margin-top: 32px;
		.swiper-pagination-bullet {
			opacity: 1;
			background: #D2D2D2;
			border:none;
			width: 10px;
			height: 10px;
			margin: 0 7px;
			&.swiper-pagination-bullet-active {
				background: $turquoise;
			}
			@include on-event {
				outline: none;
			}
		}
	}
}