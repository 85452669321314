.icon-tile {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 96px;
	background: #fff;
	border-radius: 10px;
	box-shadow: 1.7px 4.7px 21px rgba(0, 0, 0, 0.12);
	padding: 10px 36px;
	text-align: left;
	@media (max-width: 1000px) {
		height: 86px;
		padding: 10px 25px;
	}

	&__img {
		display: block;
		margin-right: 28px;
		@media (max-width: 768px) {
			margin-right: 25px;
		}
	}

	&__text {
		@include SansSemi;
		font-size: 15px;
		line-height: 1.46;
		flex:1;
		@media (max-width: 768px) {
			font-size: 14px;
			line-height: 1.43;
		}
	}
}