.tile-block {
	display: block;
	height: 0;
	position: relative;
	padding-top: 83%;
	overflow: hidden;
	border-radius: 5px;
	background-size: cover;
	background-position: center bottom;
	background-repeat: no-repeat;
	
	&__heading {
		position: absolute;
		left:28px;
		bottom: 30px;
		padding-right: 16%;
		@include MontserratSemi;
		@include font(#fff,24px,32px);
	}
}