.side-bar-layout {
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
	@media (max-width: 768px) {
		display: block;
	}
	
	&__aside {
		width: 296px;
		margin-right: 26px;
		background: #FFFFFF;
		box-shadow: 1.7px 4.7px 21px rgba(0, 0, 0, 0.12);
		border-radius: 6px;
		@media (max-width: 768px) {
			width: auto;
			margin-right: 0;
			margin-bottom: 24px;
		}
	}

	&__main {
		flex: 1;
		background: #FFFFFF;
		box-shadow: 1.7px 4.7px 21px rgba(0, 0, 0, 0.12);
		border-radius: 6px;
	}

	.side-bar-layout__header {
		min-height: 150px;
		border-bottom: 1px dashed #797979;
		padding: 39px 47px 12px;
		@media (max-width: 600px) {
			padding: 30px;
		}
	}
	.side-bar-layout__body {
		padding: 36px 47px 50px;
		@media (max-width: 768px) {
			padding: 30px 47px;
		}
		@media (max-width: 600px) {
			padding: 30px;
		}
	}
}