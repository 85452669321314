.is-table {
  position: relative;
  @media (max-width: 1279px) {
    -webkit-overflow-scrolling: touch;
    overflow: auto;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    @media (max-width: 1279px) {
      width: 1256px;
    }
    @media (max-width: 768px) {
      width: 1000px;
    }
    td {
      cursor: default;
      border-collapse: collapse;
      padding: 8px 32px 4px 5px;
      vertical-align: middle;
      height: 62px;
      &:first-child {
        padding-left: 64px;
      }
      @media (max-width: 768px) {
        height: 50px;
      }
    }
    thead {
      background-color: #F9F9F9;
      td {
        @include SansSemi;
      }
    }
    tbody {
      background: #fff;
      
      .accent-text {
        color: #6177FF;
      }
      td:nth-child(3) {
        img {
          margin: 0 0 0 22px;
        }
      }
      td:nth-child(4) {
        img {
          margin: 0 0 0 14px;
        }
      }
      tr:nth-child(even){
        background: #F9F9F9;
      }
      tr.closed {
        display: none;
      }
    }
  }
}