.is-hero {
	position: relative;
	min-height: 695px;
	max-height: 100vh;
	overflow: hidden;
	@media (max-width: 768px) {
		min-height: 418px;
	}

	.swiper-container {
		position: absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
	}

	.swiper-pagination {
		bottom: 127px;
		@media (max-width: 1279px) {
			bottom: 82px;
			left:0;
			right:0;
		}
	}

	.swiper-pagination-bullet {
		background: transparent;
		opacity: 1;
		border: 1px solid #fff;
		&.swiper-pagination-bullet-active {
			background: $turquoise;
			border-color: $turquoise;
		}
		@include on-event {
			outline: none;
		}
	}
	.swiper-pagination-bullet + .swiper-pagination-bullet {
		margin-left: 12px;
	}

	&__slide {

	}

	&__slide-img {
		position: absolute;
		top:0;
		left:0;
		right: 0;
		bottom: 0;
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: right center;
	}

	&__content {
		position: absolute;
		z-index: 2;
		top:238px;
		left:0;
		right:0;
		@media (max-width: 1279px) {
			.responsive-img {
				margin: 0 auto;
			}
		}
		@media (max-width: 768px){
			top:130px;
		}
	}

	&__text {
		@include SansR;
		@include font(#fff, 20px, 1.6);
		margin: 50px 0 0;
		max-width: 420px;
		@media (max-width: 1279px) {
			text-align: center;
			margin: 28px auto 0;
		}
		@media (max-width: 768px) {
			margin: 26px auto 0;
			font-size: 14px;
			line-height: 1.7;
		}
	}
}