// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.is-button {
	position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 0;
  user-select: none;
  white-space: nowrap;
  text-decoration: none;
  border: 0;
  outline: 0;
  background: 0 0;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  touch-action: manipulation;
  overflow: visible;
  font-family: inherit;
  text-align: center;
  cursor:pointer;
  transition: background-color .25s ease-out;
  @include on-event {
  	text-decoration: none;
  }

	&__text {
    @include SansSemi;
		font-size: 18px;
		padding: 0 39px;
		display: inline-block;
		position: relative;
		transition: color .25s ease-out;
    @media (max-width: 1850px) {
      font-size: 14px;
      padding: 0 20px;
    }
	}
  @media (max-width: 768px) {
    transition: background-color .25s ease-out, transform 0.05s ease-in-out;
    &:active {
      transform: scale(0.99);
    }
  }
}

// mods 

.is-button--primary {
	height: 46px;
	line-height: 46px;
  border-radius: 25px;
  min-width: 158px;
  @media (max-width: 1850px) {
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
    min-width: 98px;
  }
}

.is-button--fill {
	background: $turquoise;
	.is-button__text {
  	color: #fff;
  }
}

.is-button--arrow {
  border:1px solid #D2D2D2;
  line-height: 44px;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTVweCIgaGVpZ2h0PSIxMXB4IiB2aWV3Qm94PSIwIDAgMTUgMTEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUwLjIgKDU1MDQ3KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5WZWN0b3IgKDEpPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IlZlY3Rvci0oMSkiIGZpbGw9IiNGOUY5RjkiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xMC4xMDcyLDAuMzg1NjUyIEM5Ljg5NTcsMC4xNzQxODQgOS41NjI3OCwwLjE3NDE4NCA5LjM1MTMxLDAuMzg1NjUyIEM5LjE0Njk5LDAuNTg5OTc3IDkuMTQ2OTksMC45MzAwNDIgOS4zNTEzMSwxLjEzMzg5IEwxMy4xODM5LDQuOTY2NTIgTDAuNTM2Nzc5LDQuOTY2NTIgQzAuMjQxOTYxLDQuOTY2NTIgMS4wNGUtMDUsNS4yMDA4NiAxLjA0ZS0wNSw1LjQ5NTY3IEMxLjA0ZS0wNSw1Ljc5MDQ5IDAuMjQxOTYxLDYuMDMyNDQgMC41MzY3NzksNi4wMzI0NCBMMTMuMTgzOSw2LjAzMjQ0IEw5LjM1MTMxLDkuODU3OTMgQzkuMTQ2OTksMTAuMDY5NCA5LjE0Njk5LDEwLjQwOTkgOS4zNTEzMSwxMC42MTM4IEM5LjU2Mjc4LDEwLjgyNTMgOS44OTU3LDEwLjgyNTMgMTAuMTA3MiwxMC42MTM4IEwxNC44NDcxLDUuODczODQgQzE1LjA1MTQsNS42Njk1MiAxNS4wNTE0LDUuMzI5NDUgMTQuODQ3MSw1LjEyNTYgTDEwLjEwNzIsMC4zODU2NTIgWiIgaWQ9IlNoYXBlIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right 19px center;
  .is-button__text {
    color: #F9F9F9;
    padding: 0 83px 0 46px;
  }
  @media (max-width: 1850px) {
    line-height: 34px;
    .is-button__text {
      padding: 0 66px 0 36px;
    }
  }
}





