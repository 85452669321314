.header-nav {
	position: relative;

	&__list {
		display: flex;
		align-items: center;
		margin: 0;
		padding: 0;
		@media (max-width: 1279px) {
			display: block;
			margin: 40px 0 0; 
			padding: 0 15px 0 40px;
		}
	}

	&__item {
		display: block;
		margin: 0 0 0 30px;
		@media (min-width: 1551px) {
			margin: 0 0 0 42px;
		}
    @media (min-width: 1851px) {
    	margin: 0 0 0 85px;
    	&:first-child {
	    	margin: 0 0 0 76px;
	    }
    }
    @media (max-width: 1279px) {
    	margin: 0;
    	& + #{&} {
    		margin-top: 22px;
    	}
    }
	}

	&__text {
		@include SansSemi;
		@include font(#fff, 18px, 1.66);
    @include on-event {
    	color:#fff;
    }
    @media (min-width: 1551px) {
			font-size: 20px;
			line-height: 1.5;
		}
		@media (max-width: 1279px) {
			padding: 0 0 0 8px;
		}
	}
}