// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table;
}

/**
 * Hide text while making it readable for screen readers
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.t-center {
  text-align: center;
}

.responsive-img {
  display: block;
  width:auto;
  max-width: 100%;
  max-height: 100%;
}

.invisible {
  opacity: 0;
  visibility: hidden;
}

.hidden-1000-down {
  @media (max-width: 1000px) {
    display: none !important;
  }
}

.hidden-1001-up {
  @media (min-width: 1001px) {
    display: none !important;
  }
}

.hidden-768-down {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.hidden-769-up {
  @media (min-width: 769px) {
    display: none !important;
  }
}