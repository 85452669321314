$arrow: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNnB4IiBoZWlnaHQ9IjEwcHgiIHZpZXdCb3g9IjAgMCA2IDEwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1MC4yICg1NTA0NykgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+c20tYXJyPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9InNtLWFyciIgZmlsbD0iIzA2Mzc1OCIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPHBhdGggZD0iTTYsNC45OTk5OCBDNiw0LjgyMDc2IDUuOTI3OTcsNC42NDE1NiA1Ljc4NDIyLDQuNTA0OTIgTDEuMjU4MzIsMC4yMDUxNDIgQzAuOTcwNDEzLC0wLjA2ODM4MDcgMC41MDM2MjcsLTAuMDY4MzgwNyAwLjIxNTg0LDAuMjA1MTQyIEMtMC4wNzE5NDY3LDAuNDc4NTU0IC0wLjA3MTk0NjYsMC45MjE5MzUgMC4yMTU4NCwxLjE5NTQ4IEw0LjIyMDYxLDQuOTk5OTggTDAuMjE1OTgxLDguODA0NTEgQy0wLjA3MTgwNTcsOS4wNzgwMyAtMC4wNzE4MDU2LDkuNTIxMzcgMC4yMTU5ODEsOS43OTQ3NiBDMC41MDM3NjgsMTAuMDY4NCAwLjk3MDU1NCwxMC4wNjg0IDEuMjU4NDYsOS43OTQ3NiBMNS43ODQzNiw1LjQ5NTA0IEM1LjkyODE0LDUuMzU4MzQgNiw1LjE3OTE0IDYsNC45OTk5OCBaIiBpZD0iU2hhcGUiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==';

.es-tiles {
	position: relative;
	@media (max-width: 1000px) {
		text-align: center;
		&::before {
			content: '';
			display: block;
			position: absolute;
			width: 0;
			top:60px;
			bottom:43px;
			left: 0;
			border-left: 1px dashed #000000;
		}
	}

	&__top {
		position: relative;
		&::before {
			content: '';
			display: block;
			position: absolute;
			width: 50%;
			height: 0;
			left:-25px;
			top:60px;
			border-top: 1px dashed #000000;
		}
		.es-tiles__center-img {
			position: relative;
			z-index: 2;
			margin: 0 auto;
			max-width: 121px;
		}
	}
	
	&__row {
		position: relative;
		& + #{&} {
			margin-top: 35px;
		}
		@media (max-width: 1000px) {
			padding: 0 15px;
			.col::before {
				content: '';
				display: block;
				position: absolute;
				width: 25px;
				height: 0;
				top:50% !important;
				right:100%;
				left: auto;
				border-top: 1px dashed #000000;
			}
		}

		&--one {
			&::before {
				content: '';
				display: block;
				position: absolute;
				height: 35px;
				top:100%;
				left:93px;
				right:93px;
				margin: 0 auto;
				border: 1px dashed #000000;
				border-top: none;
			}
			.es-tiles__middle-item::before {
				top:100%;
			}
		}

		&--three {
			&::before {
				content: '';
				display: block;
				position: absolute;
				height: 35px;
				bottom:100%;
				left:93px;
				right:93px;
				margin: 0 auto;
				border: 1px dashed #000000;
				border-bottom: none;
			}
			.es-tiles__middle-item::before {
				bottom:100%;
			}
		}

		&--two {
			align-items: center;
		}
	}

	&__middle-row--left::before {
		content: '';
		display: block;
		position: absolute;
		width: 55px;
		height: 0;
		border-top: 1px dashed #000000;
		top:50%;
		left:100%;
	}

	&__middle-row--right::before {
		content: '';
		display: block;
		position: absolute;
		width: 55px;
		height: 0;
		border-top: 1px dashed #000000;
		top:50%;
		right:100%;
	}

	&__center-img {
		margin: 0 auto;
	}

	&__center-inner {
		margin: 0 auto;
		padding: 13px;
		max-width: 264px;
		border-left: 1px dashed #000000;
		border-right: 1px dashed #000000;
	}

	&__middle-item {
		&::before {
			content:'';
			display: block;
			position: absolute;
			height: 35px;
			width: 0;
			left:50%;
			border-left: 1px dashed #000000;
		}

		&--left::before {
			left:54px;
			right:auto;
		}

		&--right::before {
			left:auto;
			right: 54px;
		}
	}

	.is-columns .col {
		position: relative;
	}

	.deco-arrow {
		position: absolute;
		z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    background: #fff;
    border-radius: 14px;
    text-align: center;
    line-height: 26px;
    box-shadow: 1.7px 4.7px 21px rgba(0, 0, 0, 0.12);
	}
}

// mods
.es-tiles--mobile {
	.icon-tile {
		margin: 22px 0 0;
		position: relative;
		&::before {
			content: '';
			display: block;
			position: absolute;
			right: 100%;
			top:50%;
			width: 25px;
			height: 0;
			border-top: 1px dashed #000000;
		}
	}
}
