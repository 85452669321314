.page-common-info {
	background: #f2f2f2;
	padding: 200px 0 86px;
	font-size: 14px;
	line-height: 22px;
	color: #7A7A7A;
	.section-heading {
		max-width: 670px;
		margin: 0 auto 57px;
	}
	.heading {
		@include MontserratB;
		margin: 0 0 13px;
		@include font(#000, 22px, 1.22);
	}
	.side-bar-layout__body {
		@include is-grid(3,55);
		@media (max-width: 1100px) {
			@include is-grid(2,40);
		}
		@media (max-width: 600px) {
			@include is-grid(1,60);
		}
	}
}